'use client';

import { useEffect } from 'react';

import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

import logo from '@/public/img/logo/hondubetlogo.png';

const NotFoundPage: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    // Redirect to the homepage after 30 seconds
    const timer = setTimeout(() => {
      router.push('/');
    }, 10000);

    // Cleanup the timer when the component is unmounted
    return () => { return clearTimeout(timer); };
  }, [router]);

  return (
    <div className="h-screen flex flex-col justify-center items-center font-sans overflow-hidden">
      <Head>
        <title>Página no encontrada</title>
      </Head>
      <div>
        <Image src={logo} alt="Logo de Hondubet" width={300} height={100} />
      </div>
      <h1 className="text-2xl text-white my-4 text-center">
        ¡Lo sentimos! Hemos movido esta página.
      </h1>
      <p className="text-lg pb-20 max-w-3/4 text-center leading-6 text-white">
        La página que estás buscando ha sido movida. Puedes regresar a la página principal.
      </p>
      <button
        type="button"
        onClick={() => { return router.push('/'); }}
        className="button-primary-full mx-auto hover:bg-sivarbet-primary-hover px-6 py-2 text-white bg-blue-500 rounded-md"
        style={{ maxWidth: '200px', width: '100%' }} // Limits the width to 200px and allows it to shrink if necessary
      >
        Ir a la página principal
      </button>
    </div>
  );
};

export default NotFoundPage;
